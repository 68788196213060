jQuery(function () {
  $(".js-anchor").on("click", function (event) {
    event.preventDefault();
    const target = $(this.getAttribute("href"));
    if (target.length) {
      event.preventDefault();
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: target.offset().top - 130,
          },
          1000
        );
    }
  });
});
