// Grabbed from https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#example
// Modified to be universally usable and functional with nested tabs

jQuery(function () {
  const tabsContainers = document.querySelectorAll(".js-tabs");

  tabsContainers.forEach((container) => {
    /** @type {HTMLElement | null} */
    const tabList = container.querySelector('[role="tablist"]');
    /** @type {NodeListOf<HTMLElement> | undefined} */
    const tabs = tabList ? tabList.querySelectorAll('[role="tab"]') : undefined;

    if (!tabList || !tabs) return;

    // Add a click event handler to each tab
    tabs.forEach((tab) => {
      tab.addEventListener("click", changeTabs);
    });

    // Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;

    tabList.addEventListener("keydown", (e) => {
      // Move right
      if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
        tabs[tabFocus].setAttribute("tabindex", "-1");
        if (e.key === "ArrowRight") {
          tabFocus++;
          // If we're at the end, go to the start
          if (tabFocus >= tabs.length) {
            tabFocus = 0;
          }
          // Move left
        } else if (e.key === "ArrowLeft") {
          tabFocus--;
          // If we're at the start, move to the end
          if (tabFocus < 0) {
            tabFocus = tabs.length - 1;
          }
        }

        tabs[tabFocus].setAttribute("tabindex", "0");
        tabs[tabFocus].focus();
        tabs[tabFocus].click();
      }
    });
  });

  function changeTabs(e) {
    const target = e.currentTarget;
    const parent = target.parentNode;
    const grandparent = parent.parentNode;

    // Remove all current selected tabs
    parent
      .querySelectorAll('[aria-selected="true"]')
      .forEach((/** @type {HTMLElement} */ tab) => {
        tab.setAttribute("aria-selected", "false");
      });

    // Set this tab as selected
    target.setAttribute("aria-selected", true);

    // Hide all tab panels
    [...parent.parentNode.children]
      .filter((child) => child !== parent)
      .forEach((/** @type {HTMLElement} */ panel) => {
        panel.setAttribute("aria-hidden", "true");
      });

    // Show the selected panel
    grandparent.parentNode
      .querySelector(`#${target.getAttribute("aria-controls")}`)
      .removeAttribute("aria-hidden");
  }
});
