const closeSubmenu = (
  button,
  submenu,
  submenuActiveClass = "nav__first-sub-menu--active"
) => {
  submenu.classList.remove(submenuActiveClass);
  button.setAttribute("aria-expanded", "false");
};

const handleClickOutside = (event, button, submenu) => {
  if (
    event.target !== submenu &&
    event.target !== button &&
    !submenu.contains(event.target)
  ) {
    closeSubmenu(button, submenu);
  }
};

const handleEscape = (event, button, submenu) => {
  if (event.key === "Escape") {
    closeSubmenu(button, submenu);
  }

  document.removeEventListener("click", handleClickOutside);
  document.removeEventListener("keydown", handleEscape);
};

jQuery(function () {
  let isOpen = false;
  const nav = document.querySelector(".nav");
  const mobileToggle = document.querySelector(".js-toggle-mobile-menu");

  const openSubmenuButtons = document.querySelectorAll(".js-open-navigation");
  openSubmenuButtons.forEach((button) => {
    const openSubmenu = button.nextElementSibling;

    button.addEventListener("click", (e) => {
      const isSubmenuOpen = button.getAttribute("aria-expanded") === "true";
      button.setAttribute("aria-expanded", `${!isSubmenuOpen}`);
      openSubmenu.classList.toggle(
        "nav__first-sub-menu--active",
        !isSubmenuOpen
      );

      setTimeout(() => {
        document.addEventListener(
          "click",
          (e) => handleClickOutside(e, button, openSubmenu),
          {
            once: true,
          }
        );

        document.addEventListener("keydown", (e) =>
          handleEscape(e, button, openSubmenu)
        );
      }, 100);
    });
  });

  const closeSubmenuButtons = document.querySelectorAll(".js-close-navigation");

  closeSubmenuButtons.forEach((button) => {
    button.addEventListener("click", () => {
      button.parentElement.classList.remove("nav__first-sub-menu--active");
      button.parentElement.previousElementSibling.setAttribute(
        "aria-expanded",
        "false"
      );

      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    });
  });

  mobileToggle.addEventListener("click", () => {
    isOpen = !isOpen;

    nav.classList.toggle("nav--active");
    mobileToggle.setAttribute("aria-expanded", `${isOpen}`);

    const closeMobileMenu = () => {
      isOpen = false;
      nav.classList.remove("nav--active");
      mobileToggle.setAttribute("aria-expanded", "false");
      document.removeEventListener("click", closeMobileMenu);
    };

    if (isOpen) {
      document.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          closeMobileMenu();
        }
      });
    }
  });

  const submenuButtons = document.querySelectorAll(".js-open-submenu");

  submenuButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const isOpen = button.getAttribute("aria-expanded") === "true";
      button.setAttribute("aria-expanded", `${!isOpen}`);

      button.nextElementSibling.classList.toggle(
        "nav__second-sub-menu--active" // class to toggle
      );
    });
  });
});
