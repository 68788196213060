jQuery(function () {
  const url = window.location.href;
  const splitted_url = url.split("#");
  const categoryOpener = $(".js-open-job-category");

  if (!!categoryOpener) {
    $(".js-open-job-category").click(function () {
      const category = $(this).find(".js-job-category");
      category.slideToggle("fast");
    });

    if (!!splitted_url) {
      const category = $("#" + splitted_url[1]).find(".js-job-category");
      category.css("display", "block");
    }
  }
});
