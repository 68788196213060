jQuery(function () {
  /** @type {NodeListOf<HTMLSelectElement>} */
  const dropdowns = document.querySelectorAll(".js-download-dropdown");

  if (dropdowns.length === 0 || !dropdowns) return;

  dropdowns.forEach((dropdown) => {
    dropdown.addEventListener("change", (e) => {
      const value = e.currentTarget.value;

      const allContent =
        dropdown.parentNode.nextElementSibling.querySelectorAll(
          ".downloads__category-mobile"
        );
      const selectedContent = document.getElementById(
        `downloads__category-mobile--${value}`
      );

      allContent.forEach((content) =>
        content.setAttribute("aria-hidden", "true")
      );
      selectedContent.setAttribute("aria-hidden", "false");
    });
  });
});
