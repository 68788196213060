const tocContainer = document.querySelector('[data-js-id="table-of-contents-container"]');
const tocHeader = document.querySelector('[data-js-id="table-of-contents-header"]');
const tocHeaderText = document.querySelector('[data-js-id="table-of-contents-header-text"]');
const tocArrow = document.querySelector('[data-js-id="table-of-contents-arrow"]');

if (!!tocHeader) {

  tocHeader.addEventListener('click', function () {

    if (tocContainer.classList.contains('table-of-contents__container--active')) {
      tocHeaderText.textContent = 'Inhaltsverzeichnis (ausklappen)';
      tocContainer.classList.remove('table-of-contents__container--active');
      tocArrow.style.transform = '';
    } else {
      tocArrow.style.transform = 'rotate(180deg)';
      tocHeaderText.textContent = 'Inhaltsverzeichnis (zuklappen)';
      tocContainer.classList.add('table-of-contents__container--active');
    }
  });
}
