jQuery(function () {
  const playerContainer = document.querySelector(
    '[data-js-id="js-video-player"]'
  );
  const thumbnailElements = document.querySelectorAll(
    '[data-js-id="video-thumbnail-ce"]'
  );
  const videoSliderElements = document.querySelectorAll(
    '[data-js-id="video-slider-element"]'
  );
  const mobileMaxSize = 980;

  // OnLoad
  if (!!playerContainer) {
    const videoPlayer = playerContainer.querySelector("video");
    onLoadSetInteractedWithVideo(videoPlayer);
    configureVideoPlayer(videoPlayer);
    headerPlayerEventListener(videoPlayer);
    checkVisibilityAndEnablePiP(videoPlayer);
    pipPlayerEventListener(videoPlayer);
  }

  // config
  function configureVideoPlayer(videoPlayer) {
    videoPlayer.setAttribute("controlslist", "nodownload");
  }

  function headerPlayerEventListener(videoPlayer) {
    addInteractedEventListenerToPlayer(videoPlayer);
  }

  function pipPlayerEventListener() {
    let pipWindow = document.getElementById("custom-pip-window");

    if (!pipWindow) return;

    let pipPlayer = pipWindow.querySelector("video");

    addInteractedEventListenerToPlayer(pipPlayer);
  }

  function addInteractedEventListenerToPlayer(player) {
    let videoPlayer = document
      .querySelector('[data-js-id="js-video-player"]')
      .querySelector("video");

    if (!videoPlayer) return;

    player.addEventListener("play", function () {
      videoPlayer.setAttribute("data-js-user-interacted-with-video", "true");
    });
  }

  function checkVisibilityAndEnablePiP(videoPlayer) {
    let sidebarContainer = document.querySelector(
      ".video-page__sidebar-container"
    );
    // Im Mobilefall darf es nicht gecloned werden
    let pipVideo = videoPlayer.cloneNode(true);
    let pipWindow = document.createElement("div");

    if (!sidebarContainer) return;

    pipWindow.id = "custom-pip-window";

    pipWindow.appendChild(pipVideo);
    sidebarContainer.appendChild(pipWindow);

    let observer = new IntersectionObserver(
      function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            hidePipVideo(pipWindow, pipVideo, videoPlayer);
          } else {
            showPipVideo(pipWindow, pipVideo, videoPlayer);
          }
        });
      },
      {
        threshold: 0.5, // Set the threshold value here (between 0 and 1)
      }
    );

    observer.observe(videoPlayer);
  }

  // Play Options for TimeStamp ContentElement and Video Timestamp Slider
  if (!!thumbnailElements) {
    thumbnailElements.forEach(function (thumbnailElement) {
      thumbnailElement.addEventListener("click", function () {
        const timeStamp = thumbnailElement.dataset.jsTimestamp;
        const customPipWindow = document.getElementById("custom-pip-window");
        const videoPlayer = document
          .querySelector('[data-js-id="js-video-player"]')
          .querySelector("video");

        videoPlayer.setAttribute("data-js-user-interacted-with-video", "true");

        if (customPipWindow && window.innerWidth >= mobileMaxSize) {
          const pipVideo = customPipWindow.querySelector("video");
          playVideo(pipVideo, timeStamp);
        } else {
          if (window.innerWidth > mobileMaxSize) {
            window.scrollTo(0, 0);
          }

          playVideo(videoPlayer, timeStamp);
        }
      });
    });
  }

  if (!!videoSliderElements) {
    videoSliderElements.forEach(function (videoSliderElement, pipVideo) {
      const videoPlayer = document
        .querySelector('[data-js-id="js-video-player"]')
        .querySelector("video");

      videoSliderElement.addEventListener("click", function () {
        const timeStamp = videoSliderElement.dataset.jsTimestamp;
        videoPlayer.setAttribute("data-js-user-interacted-with-video", "true");

        if (window.innerWidth > mobileMaxSize) {
          window.scrollTo(0, 0);
        }

        const customPipWindow = document.getElementById("custom-pip-window");

        if (!!customPipWindow) {
          const pipVideo = customPipWindow.querySelector("video");

          if (!!pipVideo && !pipVideo.paused) {
            pipVideo.pause();
          }

          playVideo(videoPlayer, timeStamp);
        } else {
          playVideo(videoPlayer, timeStamp);
        }
      });
    });
  }

  // Helper Functions
  function onLoadSetInteractedWithVideo(videoPlayer) {
    videoPlayer.setAttribute("data-js-user-interacted-with-video", "false");
  }

  function hidePipVideo(pipWindow, pipVideo, videoPlayer) {
    pipWindow.style.display = "none";

    if (!!!videoPlayer || !!!pipVideo) return;

    if (!pipVideo.paused) {
      pipVideo.pause();

      if (userInteracted(videoPlayer) === true) {
        videoPlayer.currentTime = pipVideo.currentTime;
        videoPlayer.play();
      }
    }
  }

  function showPipVideo(pipWindow, pipVideo, videoPlayer) {
    if (!!!videoPlayer || !!!pipVideo) return;

    pipWindow.style.display = "flex";

    if (!videoPlayer.paused) {
      videoPlayer.pause();

      if (userInteracted(videoPlayer) === true) {
        pipVideo.currentTime = videoPlayer.currentTime;
        pipVideo.play();
      }
    }
  }

  function playVideo(player, timestamp) {
    player.currentTime = timestamp;
    player.play();
  }

  function userInteracted(videoPlayer) {
    if (videoPlayer.dataset.jsUserInteractedWithVideo === "true") return true;
  }
});
